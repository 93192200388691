<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-flex uk-flex-between uk-margin">
            <div class="uk-flex uk-flex-left">
                <h3 class="uk-margin-remove-bottom">Assign Mitra to Warung</h3>
            </div>
            <div class="uk-flex uk-flex-right">
                <button class="uk-close-large" style="color: black;" type="button" uk-close @click="$router.push(`/admin/community/mywarung/${$route.params.warung_id}`)"></button>
            </div>
        </div>
        <div class="uk-flex uk-flex-between uk-margin">
            <div class="uk-flex uk-flex-left">
                    <div class="uk-inline">
                        <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                        <input class="uk-input" @keyup.enter="(e) => changeMeta(e, 'fullname')" type="text" placeholder="Search Mitra">
                    </div>
            </div>
            <div v-if="consist(user_cred.roles, ['admin-community', 'qwe123'])" class="uk-flex uk-flex-right">
                <button class="uk-button uk-button-success uk-border-rounded uk-margin-small-right" type="button" @click="showAssignMitra = true">
                    <svg style="margin-right: 10px;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path :d="icon_plus_person" fill="#fff"/>
                    </svg>
                    Add Mitra
                </button>
                <button class="uk-button uk-button-primary uk-border-rounded" type="button" :disabled="isLoadingButton" @click="downloadReport">
                    <span uk-icon="file-text"></span> Download Report
                </button>
            </div>
        </div>
        <div class="uk-card uk-card-default">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-medium uk-table-striped">
                    <thead>
                        <tr class="uk-text-left">
                            <th>Full Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>Company</th>
                            <th>Office</th>
                            <th>Role</th>
                            <th>Total Spending</th>
                            <th v-if="consist(user_cred.roles, ['admin-community', 'qwe123'])" class="uk-width-auto uk-text-center">Action</th>
                        </tr>
                    </thead>
                    <loading-table v-if="isLoading" :colspan="7"/>
                    <tbody v-else-if="page_data.docs.length>0">
                        <tr v-for="(data, i) in page_data.docs" :key="i" class="uk-text-left">
                            <td><div class="one-line-text">
                                <a @click="$router.push(`transaction/${data.user_id}`)">{{ data.fullname }}</a>
                            </div></td>
                            <template v-if="!userDetailLoad">
                                <td><div class="one-line-text">{{ data.email }}</div></td>
                                <td><div class="one-line-text">{{ data.phone_number }}</div></td>
                                <td><div class="one-line-text">{{ data.company }}</div></td>
                                <td><div class="one-line-text">{{ data.office }}</div></td>
                                <td><div class="one-line-text">{{ data.role }}</div></td>
                            </template>
                            <template v-else>
                                <td><div uk-spinner></div></td>
                                <td><div uk-spinner></div></td>
                                <td><div uk-spinner></div></td>
                                <td><div uk-spinner></div></td>
                                <td><div uk-spinner></div></td>
                            </template>
                            <td><div class="one-line-text">Rp{{ formatter.thousandSeparator(data.total_spending) }}</div></td>
                            <td v-if="consist(user_cred.roles, ['admin-community', 'qwe123'])" class="uk-text-center">
                                <button class="uk-button uk-button-small uk-button-default" type="button" >Actions</button>
                                <div style="padding: 10px 25px;" uk-dropdown="mode: click">
                                    <ul class="uk-nav uk-dropdown-nav uk-text-left">
                                        <li><a @click="confirmDeleteAssignedMitra(data)">Delete</a></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="consist(user_cred.roles, ['admin-community', 'qwe123']) ? 6 : 5"/>
                </table>
            </div>
            <pagination
                :total-data="page_data.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>
        <AssignMitra v-if="showAssignMitra" @hideModal="hideModal" :excludeIds="assigned_mitra"/>
        <ConfirmDeleteModal v-if="deleteData.isShow" :data="deleteData" @hideModal="hideModal" @confirmDelete="confirmDelete" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { PREFIX_IMAGE } from '@/utils/constant';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import LoadingTable from '@/components/globals/tables/Loading';
import Pagination from '@/components/globals/Pagination';
import AssignMitra from '@/components/modals/community/mywarung/assign_mitra';
import ConfirmDeleteModal from '@/components/globals/modals/confirm_delete';
import { getUserLogin } from '@/utils/auth';
import formatter from "@/utils/formatter";
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import { excelDownloader } from '@/utils/helper';
import moment from 'moment';
moment.locale('id');

export default {
    data(){
        return {
            moment: moment,
            images: PREFIX_IMAGE,
            user_cred: getUserLogin(),
            meta: {
                limit: 50,
                page: 1
            },
            page_data: { docs: [], totalDocs: 0 },
            isLoading: true,
            assigned_mitra: [],
            included_ids:[],
            dataUser: null,
            dataPartnership: null,
            userDetailLoad: true,
            isLoadingButton: false,
            showAssignMitra: false,
            formatter,
            deleteData: {
                isShow: false,
                title: null,
                _id: null,
                body: {
                    "Full Name": null,
                    "Company": null,
                    "Office": null,
                }
            },
            icon_plus_person: "M5 6.75C5.79565 6.75 6.55871 6.43393 7.12132 5.87132C7.68393 5.30871 8 4.54565 8 3.75C8 2.95435 7.68393 2.19129 7.12132 1.62868C6.55871 " +
                "1.06607 5.79565 0.75 5 0.75C4.20435 0.75 3.44129 1.06607 2.87868 1.62868C2.31607 2.19129 2 2.95435 2 3.75C2 4.54565 2.31607 5.30871 2.87868 5.87132C3.44129 " +
                "6.43393 4.20435 6.75 5 6.75ZM11.75 6.75C12.3467 6.75 12.919 6.51295 13.341 6.09099C13.7629 5.66903 14 5.09674 14 4.5C14 3.90326 13.7629 3.33097 13.341 2.90901C12.919 " +
                "2.48705 12.3467 2.25 11.75 2.25C11.1533 2.25 10.581 2.48705 10.159 2.90901C9.73705 3.33097 9.5 3.90326 9.5 4.5C9.5 5.09674 9.73705 5.66903 10.159 6.09099C10.581 6.51295 " +
                "11.1533 6.75 11.75 6.75ZM2.1875 8.25C1.73995 8.25 1.31072 8.42779 0.994257 8.74426C0.67779 9.06073 0.5 9.48995 0.5 9.9375V10.125C0.5 10.125 0.5 13.5 5 13.5C6.0425 13.5 6.8435 " +
                "13.3192 7.4585 13.0402C7.31979 12.5813 7.24953 12.1044 7.25 11.625C7.25 10.3822 7.715 9.24825 8.48 8.38725C8.26919 8.29644 8.04203 8.24973 7.8125 8.25H2.1875ZM16.25 11.625C16.25 " +
                "10.531 15.8154 9.48177 15.0418 8.70818C14.2682 7.9346 13.219 7.5 12.125 7.5C11.031 7.5 9.98177 7.9346 9.20818 8.70818C8.4346 9.48177 8 10.531 8 11.625C8 12.719 8.4346 13.7682 " +
                "9.20818 14.5418C9.98177 15.3154 11.031 15.75 12.125 15.75C13.219 15.75 14.2682 15.3154 15.0418 14.5418C15.8154 13.7682 16.25 12.719 16.25 11.625ZM12.5 12L12.5007 13.8773C12.5007 " +
                "13.9767 12.4612 14.0721 12.3909 14.1424C12.3206 14.2127 12.2252 14.2523 12.1257 14.2523C12.0263 14.2523 11.9309 14.2127 11.8606 14.1424C11.7903 14.0721 11.7507 13.9767 11.7507 " +
                "13.8773V12H9.872C9.77254 12 9.67716 11.9605 9.60683 11.8902C9.53651 11.8198 9.497 11.7245 9.497 11.625C9.497 11.5255 9.53651 11.4302 9.60683 11.3598C9.67716 11.2895 9.77254 11.25 " +
                "9.872 11.25H11.75V9.375C11.75 9.27554 11.7895 9.18016 11.8598 9.10983C11.9302 9.03951 12.0255 9 12.125 9C12.2245 9 12.3198 9.03951 12.3902 9.10983C12.4605 9.18016 12.5 9.27554 " +
                "12.5 9.375V11.25H14.3727C14.4722 11.25 14.5676 11.2895 14.6379 11.3598C14.7082 11.4302 14.7477 11.5255 14.7477 11.625C14.7477 11.7245 14.7082 11.8198 14.6379 11.8902C14.5676 " +
                "11.9605 14.4722 12 14.3727 12H12.5Z"
        };
    },
    components: {
        EmptyTable,
        Pagination,
        LoadingTable,
        AssignMitra,
        ConfirmDeleteModal,
    },
    watch: {
        async meta() {
            const data = await this.searchUserByFullname({user_ids: this.assigned_mitra, fullname: this.meta.fullname});
            for (const cust of data.result) {
                this.included_ids.push(cust._id);
            }
            this.getInitPageData();
        },
    },
    async mounted() {
        await this.getAllPageData();
    },
    methods: {
        ...mapActions({
            getAssignedMitra: 'warung/getAssignedMitra',
            getAllAssignedMitra: 'warung/getAllAssignedMitra',
            deleteAssignedMitra: 'warung/deleteAssignedMitra',
            getUserFullname: 'internal_api/getUserFullname',
            getUserPartnership: 'internal_api/getUserPartnership',
            searchUserByFullname: 'internal_api/searchUserByFullname',
            downloadReportAssignedMitra: 'warung/downloadReportAssignedMitra',
        }),
        async getAllPageData() {
            const assigned_mitra_data = await this.getAllAssignedMitra({ id: this.$route.params.warung_id });
            for (const cust of assigned_mitra_data) {
                this.assigned_mitra.push(cust.user_id);
            }
            [this.dataUser, this.dataPartnership] = await Promise.all([
                this.getUserFullname({user_ids: this.assigned_mitra}),
                this.getUserPartnership({user_ids: this.assigned_mitra})
            ]);
            await this.getInitPageData();
        },
        async getUserData(){
            this.userDetailLoad = true;
            if (this.dataUser && this.dataPartnership) {
                for (const pg_data of this.page_data.docs) {
                    const [partner, user] = await Promise.all([
                        this.dataPartnership.result.find((data) => data.user_id.toString() === pg_data.user_id.toString()),
                        this.dataUser.result.find((data) => data._id.toString() === pg_data.user_id.toString()),
                    ]);
                    if (partner) {
                        pg_data.company = partner.company_id.name ?? "-";
                        pg_data.office = partner.company_office_id.name ?? "-";
                        pg_data.role = partner.role_id.name ?? "-";
                    } else {
                        pg_data.company = "-";
                        pg_data.office = "-";
                        pg_data.role = "-";
                    }
                    if (user) {
                        pg_data.fullname = user.fullname ?? "-";
                        pg_data.email = user.email ?? "-";
                        pg_data.phone_number = user.phone_number ?? "-";
                    } else {
                        pg_data.fullname = "-";
                        pg_data.email = "-";
                        pg_data.phone_number = "-";
                    }
                }
            }
            this.userDetailLoad = false;
        },
        async getInitPageData(){
            this.isLoading = true;
            this.page_data = await this.getAssignedMitra({ id: this.$route.params.warung_id, meta: {...this.meta, user_ids: this.included_ids} });
            if (this.page_data && this.page_data.docs.length > 0) {
                await this.getUserData();
            }
            this.deleteData.title = `Are you sure to delete this Mitra from ${this.page_data.warung.name}?`;
            this.isLoading = false;
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
        hideModal(isRefresh){
            if (isRefresh) this.getAllPageData();
            this.showAssignMitra = false;
            this.deleteData.isShow = false;
        },
        confirmDeleteAssignedMitra(data) {
            this.deleteData._id = data._id;
            this.deleteData.body["Full Name"] = data.fullname;
            this.deleteData.body["Company"] = data.company;
            this.deleteData.body["Office"] = data.office;
            
            this.deleteData.isShow = true;
        },
        async confirmDelete(id) {
            this.isLoading = true;
            const response = await this.deleteAssignedMitra({ w_id: this.$route.params.warung_id, id });
            if (response && response.status === 'OK') {
                notificationSuccess('deleted!');
                this.hideModal(true);
            } else {
                this.isLoading = false;
                notificationDanger('Failed to delete!');
            }
        },
        async getUserDataExcel(dataChange){
            if (this.dataUser && this.dataPartnership) {
                for (const pg_data of dataChange) {
                    const [partner, user] = await Promise.all([
                        this.dataPartnership.result.find((data) => data.user_id.toString() === pg_data["User ID"].toString()),
                        this.dataUser.result.find((data) => data._id.toString() === pg_data["User ID"].toString()),
                    ]);
                    pg_data["Assigned Date"] = moment(pg_data["Assigned Date"]).format('DD-MM-YYYY');
                    if (partner) {
                        pg_data["Company"] = partner.company_id.name ?? "-";
                        pg_data["Office"] = partner.company_office_id.name ?? "-";
                        pg_data["Role"] = partner.role_id.name ?? "-";
                    } else {
                        pg_data["Company"] = "-";
                        pg_data["Office"] = "-";
                        pg_data["Role"] = "-";
                    }
                    if (user) {
                        pg_data["Fullname"] = user.fullname ?? "-";
                        pg_data["Email"] = user.email ?? "-";
                        pg_data["Phone Number"] = user.phone_number ?? "-";
                    } else {
                        pg_data["Fullname"] = "-";
                        pg_data["Email"] = "-";
                        pg_data["Phone Number"] = "-";
                    }
                }
            }
            return dataChange;
        },
        async downloadReport() {
            this.isLoadingButton = true;

            const response = await this.downloadReportAssignedMitra(this.$route.params.warung_id);
            response.result = await this.getUserDataExcel(response.result);
            if (response && response.status === 'OK') {
                excelDownloader(response.result, response.filename);
                notificationSuccess('Downloaded!');
            } else {
                notificationDanger(response.message);
            }

            this.isLoadingButton = false;
        },
    },
};
</script>

<style scoped>
.one-line-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;
}
</style>
