<template>
  <section>
    <div id="mainModal" uk-modal esc-close="false" bg-close="false">
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close @click="hideModal(false)"></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title">Add Mitra</h2>
        </div>
        <div class="uk-modal-body">
          <div class="uk-margin">
            <label class="uk-form-label">Company <b class="uk-text-danger">*</b></label>
            <select class="uk-select" v-model="company.selected" @change="loadOffice">
                <option v-for="(cpn, cKey) in company.data" :key="cKey" :value="cpn._id">{{ cpn.name }}</option>
            </select>
          </div>
          <div class="uk-margin">
            <label class="uk-form-label">Office <b class="uk-text-danger">*</b></label>
            <select class="uk-select" v-model="office.selected" @change="loadRole" :disabled="!company.selected">
                <option v-for="(ofc, cKey) in office.data" :key="cKey" :value="ofc._id">{{ ofc.name }}</option>
            </select>
          </div>
          <div class="uk-margin">
            <label class="uk-form-label">Role <b class="uk-text-danger">*</b></label>
            <select class="uk-select" v-model="role.selected" @change="loadMitra" :disabled="!office.selected">
                <option v-for="(rle, cKey) in role.data" :key="cKey" :value="rle._id">{{ rle.name }}</option>
            </select>
          </div>
          <div class="uk-margin">
            <label class="uk-form-label">Mitra List <b class="uk-text-danger">*</b></label>
            <div style="border: 1px solid #CCCCCC; border-bottom: 0;">
              <div style="padding: .5rem; background: #FFFFFF">
                <div class="uk-flex uk-flex-between">
                  <div>
                    <label>
                      <input
                        class="uk-checkbox uk-margin-small-right"
                        type="checkbox"
                        value="all"
                        :disabled="mitras.length <= 0"
                        @change="actionAll"
                      >
                      All Mitra
                    </label>
                  </div>
                  <div>({{ `${user_ids.length}/${mitras.length}` }})</div>
                </div>
              </div>
            </div>
            <div class="mitra-list" style="border: 1px solid #CCCCCC;" uk-overflow-auto>
              <div
                v-for="(mitra, mKey) in mitras"
                :key="mKey"
                style="padding: .5rem;"
                :style="{backgroundColor: bgColorSelect(mKey)}"
              >
                <label>
                  <input
                    class="uk-checkbox uk-margin-small-right"
                    type="checkbox"
                    :value="mitra._id"
                    :checked="ischecked(mitra._id)"
                    @change="action"
                  >
                  <img
                    class="uk-border-circle"
                    :src="mitra.photo_url ? mitra.photo_url.file_url : '/favicon.png'"
                    alt="Border circle" style="width: 20px; height: 20px;"
                  />
                  {{ mitra.fullname }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
          <button v-else class="uk-button uk-button-primary uk-border-rounded" type="button" @click="sendForm" :disabled="user_ids.length <= 0">Save</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import formatter from "@/utils/formatter";
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
export default {
    props: {
        excludeIds: {
            type: Array,
            required: true,
        },
    },
    data(){
        return {
            company: {
                data: [],
                selected: null,
            },
            office: {
                data: [],
                selected: null,
            },
            role: {
                data: [],
                selected: null,
            },
            mitras: [],
            user_ids: [],
            isLoading: false,
        };
    },
    mounted() {
        window.UIkit.modal('#mainModal').show();
        this.loadCompany();
    },
    watch: {
        company: {
            handler: function () {
                this.office = {
                    data: [],
                    selected: null
                };
            },
            deep: true,
        },
        office: {
            handler: function () {
                this.role = {
                    data: [],
                    selected: null
                };
            },
            deep: true,
        },
        role: {
            handler: function () {
                this.mitras = [];
            },
            deep: true,
        },
    },
    methods: {
        ...mapActions({
            getCompanyOpt: 'company/getCompanyOpt',
            getOfficeOpt: 'warung/getOfficeOpt',
            getRoleOpt: 'warung/getRoleOpt',
            getMitraOpt: 'internal_api/getMitraOpt',
            assignMitra: 'warung/assignMitra',
        }),
        hideModal(isRefresh) {
            window.UIkit.modal('#mainModal').$destroy(true);
            this.$emit("hideModal", isRefresh);
        },
        formatText() {
            const balance = this.form.start_balance.replace(/[^0-9]/g, '');
            this.form.start_balance = formatter.thousandSeparator(balance);
        },
        async loadCompany(){
            this.company.data = await this.getCompanyOpt();
        },
        async loadOffice(){
            this.office.data = await this.getOfficeOpt(this.company.selected);
        },
        async loadRole(){
            this.role.data = await this.getRoleOpt({ c_id: this.company.selected, o_id: this.office.selected });
        },
        async loadMitra(){
            this.mitras = await this.getMitraOpt({ c_id: this.company.selected, o_id: this.office.selected, r_id: this.role.selected, user_ids: this.$props.excludeIds });
        },
        bgColorSelect(key) {
            return key % 2 == 0 ? '#F8F8F8' : '#FFFFFF';
        },
        action(e) {
            const target = e.target;
            if (target.checked) {
                this.user_ids.push(target.value);
            } else {
                const index = this.user_ids.findIndex((mitra) => mitra === target.value);

                if (index > -1) {
                    this.user_ids.splice(index, 1);
                }
            }
        },
        actionAll(e) {
            const target = e.target;
            if (target.checked) {
                this.user_ids = this.mitras.map(mitra => mitra._id);
            } else {
                this.user_ids = [];
            }
        },
        ischecked(value) {
            return this.user_ids.includes(value);
        },
        async sendForm() {
            try {
                this.isLoading = true;
                const response = await this.assignMitra({ id: this.$route.params.warung_id, user_ids: this.user_ids });
                this.isLoading = false;

                if (response && response.status === 'OK') {
                    notificationSuccess('Saved!');
                    this.hideModal(true);
                } else {
                    notificationDanger('Failed to save!');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
    }
};
</script>

<style scoped>
.mitra-list {
  min-height: 0 !important;
}
</style>
